<template>
  <b-container fluid class="mt-3">
    <b-overlay :show="loading" rounded="true" variant="transparent" class="p-3">
      <b-form @submit.prevent="updateArticle">
        <b-row>
          <b-col>
            <b-row align-v="end">
              <b-col cols="*" class="ml-3">
                <h2 class="m-0 p-0">Edit Article</h2>
              </b-col>
              <b-col v-if="article">
                <font size="2.3" class="text-muted">
                  <span
                    @click="
                      updateClipboard(
                        `https://TODO: CHANGE THIS/articles/${article.slug}`
                      )
                    "
                    style="cursor: pointer"
                  >
                    {{ `https://TODO: CHANGE THIS/articles/${article.slug}` }}
                    (Click to Copy)
                  </span>
                </font>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="*" class="mr-3">
            <b-button variant="secondary" @click="previewArticle"
              >Preview</b-button
            >
          </b-col>

          <b-col cols="*" class="mr-3">
            <b-button variant="primary" type="submit"
              >Update <b-spinner small v-if="updating"
            /></b-button>
          </b-col>
        </b-row>
        <hr class="mt-1 mb-2" />

        <b-row class="mb-3 mx-0" align-h="between" v-if="article">
          <b-col cols="*">
            <p class="text-muted mb-0">
              Created on {{ toLocalTime(article.created_at) }}
            </p>
          </b-col>
          <b-col cols="*">
            <p class="text-muted mb-0">
              Updated on {{ toLocalTime(article.edited_at) }}
            </p>
          </b-col>
          <b-col cols="*">
            <p class="text-muted mb-0" v-if="article.publish_date">
              Published on
              {{ toLocalTime(article.publish_date) }}
            </p>
            <p class="text-muted mb-0" v-else>Never published</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="9">
            <b-form-input
              placeholder="Enter title"
              v-model="title"
              autocomplete="off"
              class="mb-3"
              required
            ></b-form-input>
            <b-row class="mb-3" align-v="center">
              <b-col>
                <b-form-input
                  autocomplete="off"
                  placeholder="Leave blank or enter slug i.e., some-article-slug"
                  v-model="slug"
                ></b-form-input>
              </b-col>
              <b-col>
                <v-select
                  label="name"
                  :options="all_authors"
                  :multiple="true"
                  v-model="authors"
                  placeholder="Add authors"
                >
                </v-select>
              </b-col>
              <b-col cols="*" class="mr-3">
                <b-form-checkbox
                  v-model="show_authors"
                  name="check-button"
                  switch
                  size="lg"
                >
                  Show author(s)
                </b-form-checkbox>
              </b-col>
              <b-col cols="*" class="mr-3">
                <b-form-checkbox
                  v-model="published"
                  name="check-button"
                  switch
                  size="lg"
                >
                  Publish
                </b-form-checkbox>
              </b-col>
              <b-col cols="*" class="mr-3">
                <b-form-checkbox
                  v-model="full_width"
                  name="check-button"
                  switch
                  size="lg"
                >
                  Full width
                </b-form-checkbox>
              </b-col>
            </b-row>

            <editor
              api-key="q0awhe295b0tc22uvdwyerg0ixibx092yk0gqklk8c7dbsap"
              v-model="content"
              :init="init"
            />
          </b-col>

          <b-col cols="3">
            <b-form-select
              v-model="article_type"
              :options="options"
              class="mb-3"
              required
            ></b-form-select>

            <b-form-tags
              input-id="keywords"
              v-model="keywords"
              placeholder="Add keywords"
              class="mb-3"
            ></b-form-tags>

            <b-form-textarea
              v-model="description"
              placeholder="Enter description"
              rows="5"
              class="mb-3"
            >
            </b-form-textarea>

            <b-form-checkbox
              v-model="show_thumbnail"
              name="check-button"
              switch
              size="lg"
              class="mb-3"
            >
              Show thumbnail
            </b-form-checkbox>

            <b-form-input
              autocomplete="off"
              placeholder="Enter thumbnail URL or upload image"
              v-model="thumbnail_url"
              required
              class="mb-3"
            ></b-form-input>

            <b-img-lazy
              v-if="thumbnail_url.length != 0"
              :src="thumbnail_url"
              fluid
              class="mb-3"
            />
            <b-form-file
              v-model="thumbnail_file"
              :state="Boolean(thumbnail_file)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              class="mb-3"
            ></b-form-file>
            <b-row>
              <b-col>
                <b-button
                  class="mr-2"
                  variant="primary"
                  @click="uploadThumbnail"
                  block
                  :disabled="!Boolean(thumbnail_file) || uploading"
                  >Upload <b-spinner small v-if="uploading"
                /></b-button>
              </b-col>
              <b-col>
                <b-button
                  @click="thumbnail_file = null"
                  :disabled="uploading"
                  block
                  >Clear</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-container>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    editor: Editor,
  },
  data() {
    return {
      loading: true,
      article: null,
      all_authors: [],
      article_type: null,
      options: [
        { value: null, text: "Please select an article type" },
        { value: "article", text: "Article" },
        { value: "video", text: "Video" },
        { value: "faq", text: "FAQ" },
        { value: "rulesofthumb", text: "Rules of Thumb" },
      ],

      uploading: false,
      thumbnail_file: null,
      updating: false,
      title: "",
      slug: "",
      published: true,
      show_authors: true,
      full_width: false,
      description: "",
      authors: [],
      keywords: [],
      thumbnail_url: "",
      show_thumbnail: true,
      content: "",
      init: {
        height: 500,
        image_advtab: true,
        plugins: [
          "advlist autolink lists link image imagetools charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "insert | undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help",
        images_upload_handler: async function (
          blobInfo,
          success,
          failure,
          progress
        ) {
          const re = /(?:\.([^.]+))?$/;

          const ext = re.exec(blobInfo.filename())[1];
          try {
            let response = await this.$http.post(
              this.$store.state.apiURL + "/media",
              {
                name: "",
                description: "article thumbnail",
                media_type: "image",
                file_extension: ext,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.JWT,
                },
              }
            );

            var options = {
              headers: {
                "Content-Type": "image/" + ext,
                "x-amz-acl": "public-read",
              },
              onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                progress(percentCompleted);
              },
            };

            await this.$http.put(
              response.data.presigned_url,
              blobInfo.blob(),
              options
            );

            let confirmResponse = await this.$http.post(
              this.$store.state.apiURL + "/media/" + response.data.id,
              {
                confirm_action: "url",
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.JWT,
                },
              }
            );

            success(confirmResponse.data.url);
          } catch (error) {
            failure("Failed to upload file.");
          }
        }.bind(this),
      },
    };
  },
  methods: {
    updateClipboard(newClip) {
      navigator.clipboard.writeText(newClip).then(
        function () {
          /* clipboard successfully set */
          this.showToast(
            "URL succesfully copied to clipboard!",
            "Success!",
            "success"
          );
        }.bind(this),
        function () {
          /* clipboard write failed */
          this.showToast(
            "Could not copy URL to clipboard!",
            "Error!",
            "danger"
          );
        }.bind(this)
      );
    },
    previewArticle() {
      this.$router.push({
        name: "previewArticle",
        params: { id: this.article.id },
      });
    },
    toLocalTime(timeString) {
      let date = new Date(Date.parse(timeString));
      return date.toLocaleDateString();
    },
    async uploadThumbnail() {
      this.uploading = true;
      try {
        const re = /(?:\.([^.]+))?$/;

        const ext = re.exec(this.thumbnail_file.name)[1];
        let response = await this.$http.post(
          this.$store.state.apiURL + "/media",
          {
            name: "",
            description: "article thumbnail",
            media_type: "image",
            file_extension: ext,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        var options = {
          headers: {
            "Content-Type": "image/" + ext,
            "x-amz-acl": "public-read",
          },
        };

        await this.$http.put(
          response.data.presigned_url,
          this.thumbnail_file,
          options
        );

        let confirmResponse = await this.$http.post(
          this.$store.state.apiURL + "/media/" + response.data.id,
          {
            confirm_action: "url",
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.thumbnail_url = confirmResponse.data.url;
        this.thumbnail_file = null;
      } catch (error) {
        this.handleError(error);
      }
      this.uploading = false;
    },
    async updateArticle() {
      this.updating = true;
      try {
        // update article
        let response = await this.$http.put(
          this.$store.state.apiURL + "/articles/" + this.id,
          {
            title: this.title,
            slug: this.slug,
            html: this.content,
            description: this.description,
            thumbnail_url: this.thumbnail_url,
            show_thumbnail: this.show_thumbnail,
            authors: this.authors.map((author) => author.id),
            show_authors: this.show_authors,
            full_width: this.full_width,
            published: this.published,
            keywords: this.keywords,
            article_type: this.article_type,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.article = response.data;
      } catch (error) {
        this.handleError(error);
      }
      this.updating = false;
    },
  },
  async created() {
    // load in users
    let users = await this.$http.get(
      this.$store.state.apiURL + "/users/admins",
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
        params: {
          admin: true,
        },
      }
    );

    let user = await this.$http.get(
      this.$store.state.apiURL + "/users/admins/" + this.$store.state.id,
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
      }
    );
    let temp = users.data.data.concat([user.data]);
    this.all_authors = temp.map((user) => {
      return {
        id: user.id,
        name: user.first_name + " " + user.last_name,
      };
    });

    // load in article
    let article = await this.$http.get(
      this.$store.state.apiURL + "/articles/" + this.id,
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
      }
    );
    this.article = article.data;
    this.title = this.article.title;
    this.slug = this.article.slug;
    this.content = this.article.html;
    this.show_authors = this.article.show_authors;
    if (this.article.authors) {
      this.authors = this.article.authors.map(
        (id) => this.all_authors.filter((auth) => auth.id == id)[0]
      );
    }
    this.published = this.article.published;
    this.full_width = this.article.full_width;
    this.thumbnail_url = this.article.thumbnail_url;
    this.show_thumbnail = this.article.show_thumbnail;
    this.keywords = this.article.keywords;
    this.article_type = this.article.article_type;
    this.description = this.article.description;
    this.loading = false;
  },
};
</script>

<style>
</style>